























































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class LeaveMes extends Vue {
  @Prop({}) private type!: any
  @Prop({}) private date!: any
  @Prop({}) private data!: any
}
