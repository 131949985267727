





































import { Component, Vue, Model, Emit, Prop } from 'vue-property-decorator'
import FlexTooltip from '@/components/FlexTooltip.vue'
@Component({
  components: {
    FlexTooltip,
  },
})
export default class ConfirmModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  // 接受父组件的值
  @Prop({ default: () => [] }) private data!: Array<any>

  private loading: boolean = false
  private selectedRowKeys: Array<any> = []

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'title',
        title: this.$tc('message.recipient', 1),
        scopedSlots: { customRender: 'recipient' },
      },
      {
        dataIndex: 'type',
        key: 'type',
        title: this.$t('common.type'),
        scopedSlots: { customRender: 'type' },
      },
      {
        dataIndex: 'admissionTime',
        key: 'admissionTime',
        title: this.$t('myClass.student.admissionTime'),
        scopedSlots: { customRender: 'admissionTime' },
      },
    ]
  }

  private onSelectChange(selectedRowKeys): void {
    this.selectedRowKeys = selectedRowKeys
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  private confirm(): any {
    this.$emit('confirm', this.selectedRowKeys)
    this.closeModal()
  }
}
