var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-modal',{staticClass:"set-rec-modal",attrs:{"visible":_vm.visible,"title":_vm.$t('message.setRecGroup'),"destroyOnClose":"","centered":"","maskClosable":false,"footer":null,"width":600},on:{"cancel":_vm.closeModal}},[_c('div',{staticClass:"button-container"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addGroup}},[_c('a-icon',{attrs:{"type":"plus-circle","theme":"outlined"}}),_vm._v(" "+_vm._s(_vm.$t('message.addGroup'))+" ")],1),_c('a-radio-group',{model:{value:(_vm.currentType),callback:function ($$v) {_vm.currentType=$$v},expression:"currentType"}},_vm._l((_vm.types),function(type){return _c('a-radio-button',{key:type.key,attrs:{"value":type.key}},[_vm._v(" "+_vm._s(type.value)+" ")])}),1)],1),_c('div',{key:_vm.currentType,staticClass:"rec-main-content"},[_c('div',{staticClass:"group-container"},[(_vm.loading || _vm.data4Type.length)?_c('a-spin',{attrs:{"spinning":_vm.loading}},_vm._l((_vm.data4Type),function(group){return _c('div',{key:group.id,staticClass:"single-rec"},[_c('span',[_vm._v(_vm._s(group.name))]),_c('div',{staticClass:"flex-style"},[_c('a-icon',{attrs:{"type":"user"}}),_c('text-fold',{attrs:{"value":group.members
                  .map(function (member) { return (
                      member.enName +
                      ' ' +
                      member.name +
                      ' ' +
                      _vm.$t('relation.' + member.relationship)
                    ).trim(); }
                  )
                  .join('、'),"fold":true}})],1),_c('div',{staticClass:"operation"},[_c('a',{on:{"click":function($event){return _vm.edit(group)}}},[_vm._v(_vm._s(_vm.$t('common.edit')))]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a-popconfirm',{attrs:{"title":_vm.$t('common.deleteConfirm'),"getPopupContainer":function () { return _vm.$document.body; }},on:{"confirm":function($event){return _vm.deleteGroup(group)}}},[_c('a',[_vm._v(_vm._s(_vm.$t('common.delete')))])])],1)])}),0):_c('a-empty')],1)]),_c('a-modal',{attrs:{"visible":_vm.modalVis,"title":_vm.$tc('message.recGroup', 2, { prefix: _vm.$t(("common." + _vm.editType)) }),"width":600,"destroyOnClose":""},on:{"ok":_vm.confirm,"cancel":_vm.beforeClose}},[_c('a-form-model',{ref:"groupForm",staticClass:"group-form",attrs:{"model":_vm.groupForm}},[_c('a-form-model-item',{attrs:{"label":_vm.$t('common.name'),"rules":[
          {
            required: true,
            message: _vm.$t('common.enter'),
          },
          {
            max: 50,
            message: _vm.$t('tips.textLimit', { num: 200 }),
          },
          {
            whitespace: true,
            message: _vm.$t('common.enter'),
          } ],"prop":"name"}},[_c('a-input',{attrs:{"placeholder":_vm.$t('common.enter')},model:{value:(_vm.groupForm.name),callback:function ($$v) {_vm.$set(_vm.groupForm, "name", $$v)},expression:"groupForm.name"}})],1),_c('a-form-model-item',{attrs:{"label":_vm.$t('common.type'),"rules":[
          {
            required: true,
            message: _vm.$t('common.enter'),
          } ],"prop":"type"}},[_c('a-select',{attrs:{"placeholder":_vm.$t('common.select')},on:{"change":_vm.onTypeChange},model:{value:(_vm.groupForm.type),callback:function ($$v) {_vm.$set(_vm.groupForm, "type", $$v)},expression:"groupForm.type"}},_vm._l((_vm.types),function(type){return _c('a-select-option',{key:type.key},[_vm._v(_vm._s(type.value))])}),1)],1),_c('a-form-model-item',{attrs:{"label":_vm.$tc('message.recipient', 1),"required":true,"help":_vm.recipientError ? _vm.$t('common.select') : '',"validateStatus":_vm.recipientError ? 'error' : ''}},[((_vm.groupForm.members || []).length)?_c('div',{staticClass:"tag-container"},_vm._l((_vm.groupForm.members),function(item){return _c('a-tag',{key:item.id,attrs:{"visible":"","closable":""},on:{"close":function($event){return _vm.removeItem(item.id)}}},[_vm._v(_vm._s(item.title))])}),1):_vm._e(),_c('a-button',{attrs:{"block":"","type":"dashed","icon":"plus","loading":_vm.dataLoading},on:{"click":function($event){return _vm.addRec()}}},[_vm._v(_vm._s(_vm.$tc('message.recipient', 2, { prefix: _vm.$t('common.add') })))])],1)],1),_c('group-transfer',{attrs:{"title":_vm.groupTransferTitle,"defaultCandidateData":_vm.transferCandidate,"defaultSelectedData":_vm.transferSelected,"showTab":true},on:{"confirm":_vm.getSelectedData},model:{value:(_vm.groupTransferVisible),callback:function ($$v) {_vm.groupTransferVisible=$$v},expression:"groupTransferVisible"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }