

































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import {
  MessageController,
  DropDownController,
  TeacherController,
} from '@/services/request.service'
import Transfer from '@/components/Transfer.vue'
import GroupTransfer from '@/components/GroupTransfer.vue'
import Upload from '@/components/Upload.vue'
import ConfirmModal from './ConfirmModal.vue'
import moment from 'moment'
import { SimditorVue } from '@/components/simditor/Simditor'

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
])

const operations = {
  outbox: function(id) {
    return MessageController.getFromDetail(id)
  },
  inbox: function(id) {
    return MessageController.getToDetail(id)
  },
}

@Component({
  components: {
    Transfer,
    GroupTransfer,
    Upload,
    ConfirmModal,
    SimditorVue,
  },
})
export default class Send extends Vue {
  private addRange: any
  private canLeave: any = false
  private confirmData: any = []
  private confirmVis: any = false
  private dataLoading: any = false
  private formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private groupTransferVisible: any = false
  private groupTransferTitle: any = ''
  private history: Array<any> = []
  private messageForm: any = {
    sendHeadTeacher: false,
    sendTutor: false,
    sendMail: false,
    sendParent: false,
    important: false,
    attachments: [],
    content: '',
  }
  private moment = moment
  private parents: any = []
  private sending: any = false
  private students: any = []
  private teachers: any = []
  private transferType = ''
  private transferCandidate: Array<any> = []
  private transferSelected: Array<any> = []
  private uploading: any = false
  private teacherLoading: boolean = false
  private studentLoading: boolean = false
  private parentLoading: boolean = false

  private get preSelectedStudents(): Array<any> {
    return this.$store.state.messageStudents
  }
  private get recipientError(): any {
    return (
      this.messageForm.teachers &&
      !(this.messageForm.teachers || []).length &&
      this.messageForm.students &&
      !(this.messageForm.students || []).length &&
      this.messageForm.parents &&
      !(this.messageForm.parents || []).length
    )
  }
  private set recipientError(val) {
    this.$set(this.messageForm, 'teachers', [])
    this.$set(this.messageForm, 'students', [])
    this.$set(this.messageForm, 'parents', [])
  }
  private get selfId(): any {
    return this.$store.state.memberId
  }
  private get type(): any {
    return this.$route.query.type
  }

  private add(type): void {
    this.groupTransferVisible = true
    this.transferCandidate = this[type]
    this.transferSelected = this.messageForm[type]
    this.transferType = type
    this.groupTransferTitle = this.$t(`common.${type.slice(0, -1)}`) as string
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      const message = {
        title: this.messageForm.title,
        content: this.messageForm.content,
        attachments: this.messageForm.attachments?.length,
        selectedTeachers: this.messageForm.teachers?.length,
        selectedParents: this.messageForm.parents?.length,
        selectedStudents: this.messageForm.students?.length,
      }
      if (Object.values(message).some(item => item)) {
        if (!this.canLeave) {
          this.$confirm({
            title: this.$t('common.unsaveConfirm') as string,
            onOk: () => {
              next()
            },
            onCancel: () => {
              next(false)
            },
          })
        } else {
          next()
        }
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private cancel(): void {
    this.$router.back()
  }

  private created(): void {
    this.getDropDownInfo()
    this.getDetail()
  }

  private getDropDownInfo(): void {
    this.getTeachers()
    this.getStudents()
    this.getParents()
  }

  private getTeachers(): void {
    this.teacherLoading = true
    Promise.all([
      TeacherController.getListAll(),
      DropDownController.getHeadTeachers(),
      DropDownController.getCourseTeachers(),
      DropDownController.getTutors(),
      MessageController.memberGroupList('2001'),
    ])
      .then(res => {
        const allTeachers = res[0].data
          .map(teacher => {
            return {
              title: (teacher.enName + ' ' + teacher.name).trim(),
              id: teacher.teacherId,
              selected: false,
              type: 'teacher',
              relation: '',
            }
          })
          .filter(item => item.id !== this.selfId)
        const headTeachers = res[1].data
          .map((grade, index) => {
            return {
              id: index,
              expand: false,
              title: grade.groupLabel || this.$t('transfer.ungrouped'),
              children: grade.list
                .map(teacher => {
                  return {
                    id: teacher.teacherId,
                    title: teacher.teacherName,
                    titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
                  }
                })
                .filter(item => item.id !== this.selfId),
            }
          })
          .filter(item => item.children.length)
        const subjectTeachers = res[2].data
          .map((subject, index) => {
            return {
              id: index,
              expand: false,
              title: subject.groupLabel || this.$t('transfer.ungrouped'),
              children: subject.list
                .map(teacher => {
                  return {
                    id: teacher.teacherId,
                    title: teacher.teacherName,
                    titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
                  }
                })
                .filter(item => item.id !== this.selfId),
            }
          })
          .filter(item => item.children.length)
        const tutors = res[3].data
          .map((advisory, index) => {
            return {
              id: index,
              expand: false,
              title: advisory.groupLabel || this.$t('transfer.ungrouped'),
              children: advisory.list
                .map(teacher => {
                  return {
                    id: teacher.teacherId,
                    title: teacher.teacherName,
                    titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
                  }
                })
                .filter(item => item.id !== this.selfId),
            }
          })
          .filter(item => item.children.length)
        const customGroup = res[4].data
          .map((group, index) => {
            return {
              id: group.groupId,
              expand: false,
              title: group.name,
              children: group.members
                .map((member: any) => {
                  const name = (member.enName + ' ' + member.name).trim()
                  return {
                    id: member.memberId,
                    title: name,
                    isPre: member.status === '1017',
                    admissionTime: member.enterDate ? moment(member.enterDate) : moment(),
                  }
                })
                .filter(item => item.id !== this.selfId),
            }
          })
          .filter(item => item.children.length)
        this.teachers = {
          all: {
            list: allTeachers,
            transferType: 'normal',
          },
          grade: {
            list: headTeachers,
            transferType: 'group',
          },
          advisory: {
            list: tutors,
            transferType: 'group',
          },
          subjectClass: {
            list: subjectTeachers,
            transferType: 'group',
          },
          customGroup: {
            list: customGroup,
            transferType: 'group',
          },
        }
      })
      .finally(() => (this.teacherLoading = false))
  }

  private getStudents(): void {
    this.studentLoading = true
    Promise.all([
      DropDownController.getAuthStudentsGroupBySectionClass(),
      DropDownController.getAuthStudentsGroupByHouse(),
      DropDownController.getAuthStudentGroupByCourse(),
      MessageController.memberGroupList('2002'),
    ])
      .then(res => {
        let homeroomStudent = res[0].data.map(section => {
          return {
            id: section.sectionId,
            expand: false,
            title: section.sectionName || this.$t('transfer.ungrouped'),
            children: section.classStudents.map(group => {
              return {
                id: group.classId,
                title: group.className || this.$t('transfer.undivided'),
                children: group.students.map(student => ({
                  id: student.studentId,
                  title: student.name,
                  isPre: student.status === '1017',
                  admissionTime: moment(student.enterDate),
                })),
              }
            }),
          }
        })
        let advisoryStudent = res[1].data.map(advisory => {
          return {
            id: advisory.houseId,
            expand: false,
            title: advisory.houseName || this.$t('transfer.ungrouped'),
            children: advisory.subItems.map(group => {
              return {
                id: group.houseGroupId,
                title: group.houseGroupName,
                children: group.students.map(student => ({
                  id: student.studentId,
                  title: student.name,
                  isPre: student.status === '1017',
                  admissionTime: moment(student.enterDate),
                })),
              }
            }),
          }
        })
        let courseStudent = res[2].data.map(section => {
          return {
            id: section.sectionCampusId,
            expand: false,
            title: section.sectionName || this.$t('transfer.ungrouped'),
            children: section.courses.map(course => {
              return {
                id: course.courseId,
                title: course.description,
                children: course.students.map(student => ({
                  id: student.studentId,
                  title: student.name,
                  isPre: student.status === '1017',
                  admissionTime: moment(student.enterDate),
                })),
              }
            }),
          }
        })
        const customGroup = res[3].data
          .map((group, index) => {
            return {
              id: group.groupId,
              expand: false,
              title: group.name,
              children: group.members
                .map((member: any) => {
                  const name = (member.enName + ' ' + member.name).trim()
                  return {
                    id: member.memberId,
                    title: name,
                    isPre: member.status === '1017',
                    admissionTime: member.enterDate ? moment(member.enterDate) : moment(),
                  }
                })
                .filter(item => item.id !== this.selfId),
            }
          })
          .filter(item => item.children.length)
        this.students = {
          homeroom: {
            list: homeroomStudent,
            transferType: 'doubleGroup',
          },
          advisory: {
            list: advisoryStudent,
            transferType: 'doubleGroup',
          },
          subjectClass: {
            list: courseStudent,
            transferType: 'doubleGroup',
          },
          customGroup: {
            list: customGroup,
            transferType: 'group',
          },
        }
        if (this.preSelectedStudents.length) {
          let selectedStudents = [] as any
          Object.keys(this.students).forEach(key => {
            this.students[key].list.forEach(first => {
              first.children.forEach(second => {
                second.children.forEach(student => {
                  if (
                    this.preSelectedStudents.includes(student.id) &&
                    !selectedStudents.map(student => student.id).includes(student.id)
                  ) {
                    selectedStudents.push(student)
                  }
                })
              })
            })
          })
          this.$set(this.messageForm, 'students', cloneDeep(selectedStudents))
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.studentLoading = false
      })
  }

  private getParents(): void {
    this.parentLoading = true
    Promise.all([
      DropDownController.getAuthStudentParentsGroupBySectionClass(),
      DropDownController.getAuthStudentParentsGroupByHouse(),
      DropDownController.getAuthStudentParentsGroupByCourse(),
      MessageController.memberGroupList('2000'),
    ])
      .then(res => {
        let homeroomParent = res[0].data.map(section => {
          return {
            id: section.sectionId,
            expand: false,
            title: section.sectionName || this.$t('transfer.ungrouped'),
            children: section.classStudents.map(group => {
              return {
                id: group.classId,
                title: group.className || this.$t('transfer.undivided'),
                children: group.studentParents.map(parent => ({
                  id: parent.parentId + '-' + parent.studentId,
                  title: parent.studentName + this.$t('relation.' + parent.relationship),
                  selected: false,
                  isPre: parent.studentStatus === '1017',
                  admissionTime: moment(parent.enterDate),
                })),
              }
            }),
          }
        })
        let advisoryParent = res[1].data.map(advisory => {
          return {
            id: advisory.houseId,
            expand: false,
            title: advisory.houseName || this.$t('transfer.ungrouped'),
            children: advisory.subItems.map(group => {
              return {
                id: group.houseGroupId,
                title: group.houseGroupName,
                children: group.studentParents.map(parent => ({
                  id: parent.parentId + '-' + parent.studentId,
                  title: parent.studentName + this.$t('relation.' + parent.relationship),
                  selected: false,
                  isPre: parent.studentStatus === '1017',
                  admissionTime: moment(parent.enterDate),
                })),
              }
            }),
          }
        })
        let courseParent = res[2].data.map(section => {
          return {
            id: section.sectionCampusId,
            expand: false,
            title: section.sectionName || this.$t('transfer.ungrouped'),
            children: section.courses.map(course => {
              return {
                id: course.courseId,
                title: course.description,
                children: course.parents.map(parent => ({
                  id: parent.parentId + '-' + parent.studentId,
                  title: parent.studentName + this.$t('relation.' + parent.relationship),
                  selected: false,
                  isPre: parent.studentStatus === '1017',
                  admissionTime: moment(parent.enterDate),
                })),
              }
            }),
          }
        })
        const customGroup = res[3].data
          .map(group => {
            return {
              id: group.groupId,
              expand: false,
              title: group.name,
              children: group.members
                .map((member: any) => {
                  const name = (member.enName + ' ' + member.name).trim()
                  return {
                    id: member.memberId + '-' + member.studentId,
                    title: name + this.$t('relation.' + member.relationship),
                    isPre: member.status === '1017',
                    admissionTime: member.enterDate ? moment(member.enterDate) : moment(),
                  }
                })
                .filter(item => item.id !== this.selfId),
            }
          })
          .filter(item => item.children.length)
        this.parents = {
          homeroom: {
            list: homeroomParent,
            transferType: 'doubleGroup',
          },
          advisory: {
            list: advisoryParent,
            transferType: 'doubleGroup',
          },
          subjectClass: {
            list: courseParent,
            transferType: 'doubleGroup',
          },
          customGroup: {
            list: customGroup,
            transferType: 'group',
          },
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.parentLoading = false
      })
  }

  private getDetail(): void {
    if (!this.$route.query.messageId) return
    const messageId = parseInt(this.$route.query.messageId as any, 10)
    operations[this.type](messageId)
      .then(res => {
        this.history = res.data.map(item => ({
          ...item,
          toMembers: (item.toTeachers || []).concat(item.toStudents || [], item.toParents || []),
          fold: true,
        }))
        // title: (teacher.enName + ' ' + teacher.name).trim(),
        // id: teacher.teacherId,
        if (this.$route.name === 'sendMessage') {
          this.$set(this.messageForm, 'content', res.data[0].content)
          ;(this.$refs.mSim as any).changeValue(res.data[0].content)
        } else {
          let { fromMember } = this.history[0]
          //  2002 为学生  2000为家长  2001为老师
          if (fromMember) {
            switch (fromMember.memberType) {
              case '2002':
                this.messageForm.students = [
                  {
                    title: `${fromMember.enName} ${fromMember.name}`,
                    id: fromMember.memberId,
                  },
                ]
                break
              case '2000':
                this.messageForm.parents = [
                  {
                    title: fromMember.name + this.$t('relation.' + fromMember.relationship),
                    id: fromMember.memberId + '-' + fromMember.studentId,
                  },
                ]
                break
              case '2001':
                this.messageForm.teachers = [
                  {
                    title: `${fromMember.enName} ${fromMember.name}`,
                    id: fromMember.memberId,
                  },
                ]
                break
            }
          }
        }
        this.$set(
          this.messageForm,
          'title',
          (this.$route.name === 'sendMessage' ? 'Fw: ' : 'Re: ') + res.data[0].title
        )
        if (res.data[0].resources.length) {
          this.$set(
            this.messageForm,
            'attachments',
            res.data[0].resources.map(item => ({
              name: item.resourceName,
              percent: 100,
              status: 'done',
              uid: item.resourceId,
              url: item.resourceUrl,
            }))
          )
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getSelectedData(data): void {
    this.$set(this.messageForm, this.transferType, cloneDeep(data))
  }

  private removeItem(id, type): void {
    const index = this.messageForm[type]?.findIndex(teacher => teacher.id === id)
    this.messageForm[type]?.splice(index, 1)
    this.$forceUpdate()
  }

  private send(): void {
    const form = this.$refs['messageForm'] as any
    form.validate(valid => {
      let { teachers, students, parents } = this.messageForm
      let otherValid = true
      if (!(teachers || []).length && !(students || []).length && !(parents || []).length) {
        this.recipientError = true
        otherValid = false
      }
      if (!valid || !otherValid) return false
      this.sending = true
      const message = {
        ...this.messageForm,
        sendMail: this.messageForm.sendMail || false,
        sendHeadTeacher: this.messageForm.sendHeadTeacher || false,
        sendTutor: this.messageForm.sendTutor || false,
        forwardMessageId:
          this.type === 'inbox' && this.$route.name === 'sendMessage'
            ? parseInt(this.$route.query.messageId as any, 10)
            : undefined,
        forwardMasterId:
          this.type === 'outbox' && this.$route.name === 'sendMessage'
            ? parseInt(this.$route.query.messageMasterId as any, 10)
            : undefined,
        messageParentId:
          this.$route.name === 'replyMessage'
            ? parseInt(this.$route.query.messageMasterId as any, 10)
            : undefined,
        sendParent: this.messageForm.sendParent || false,
        resourceIds: this.messageForm.attachments?.map(attachment => attachment.uid),
        toTeacherIds: this.messageForm.teachers?.map(teacher => teacher.id),
        toStudentIds: this.messageForm.students?.map(student => student.id),
        toParents: this.messageForm.parents?.map(parent => {
          return {
            parentId: parseInt(parent.id.split('-')[0], 10),
            studentId: parseInt(parent.id.split('-')[1]),
          }
        }),
      }
      MessageController.sendMessage(message)
        .then(res => {
          this.$message.success(this.$tc('common.sendSuccess'))
          this.canLeave = true
          this.$route.meta.refresh = true
          this.$router.back()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.sending = false
        })
    })
  }

  private studentConfirm(ids): void {
    this.messageForm.students = this.messageForm.students
      ?.filter(item => ids.includes(item.id))
      .map(item => ({ ...item, isPre: false }))
    this.messageForm.parents = this.messageForm.parents
      ?.filter(item => ids.includes(item.id))
      .map(item => ({ ...item, isPre: false }))
    this.send()
  }

  private validateBeforeSend(): void {
    const hasPre =
      !!this.messageForm.students?.filter(item => item.isPre).length ||
      !!this.messageForm.parents?.filter(item => item.isPre).length
    if (hasPre) {
      this.confirmData = (this.messageForm.students || [])
        .map(item => ({ ...item, type: 'student' }))
        .concat((this.messageForm.parents || []).map(item => ({ ...item, type: 'parent' })))
      this.confirmVis = true
      return
    }
    this.send()
  }
}
