

























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class TextFold extends Vue {
  @Prop() private readonly value!: any
  @Prop() private readonly align!: any
  @Prop() private readonly fold!: any
  private descFold: any = true
  private descFoldShow: any = false

  @Watch('value', { immediate: true })
  private onValueChange(val) {
    if (val) {
      this.calcExpand()
    }
  }
  private calcExpand(): void {
    this.$nextTick(() => {
      if (this.descFoldShow) return
      this.descFoldShow = false
      const el: any = this.$refs.textFold
      if (!el) return
      let ch = el.clientHeight
      let sh = el.scrollHeight
      this.descFoldShow = sh > ch
      this.descFold = this.fold !== false
    })
  }
}
