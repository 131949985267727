














































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { DropDownController, MessageController } from '@/services/request.service'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import { clearEmptyArray, filterData, toPage, getSchoolInfo } from '@/utils/utils'
import { createPagination } from '@/constant/constant'
import SetRecModal from './components/SetRecModal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import SvgIcon from '@/components/SvgIcon.vue'

@Component({
  components: {
    filterGroup,
    FlexTooltip,
    SvgIcon,
    SetRecModal,
  },
})
export default class Inbox extends Vue {
  private advisories: Array<any> = []
  private data: Array<any> = []
  private filter: any = {
    title: null,
    recipient: 0,
    houseGroupId: 0,
    memberType: 0,
    sender: null,
    receivedTime: [],
    homeroomId: [],
    messageType: 0,
  }
  private init = false
  private homerooms: any = []
  private loading: boolean = false
  private moment = moment
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private recipients: Array<any> = []
  private recVis: any = false
  private reqId: any = 0

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'title',
        title: this.$t('message.title'),
        scopedSlots: { customRender: 'name' },
      },
      {
        key: 'content',
        title: this.$t('message.content'),
        ellipsis: true,
        scopedSlots: { customRender: 'content' },
      },
      {
        key: 'sender',
        title: this.$t('message.sender'),
        scopedSlots: { customRender: 'sender' },
      },
      {
        key: 'recipient',
        title: this.$tc('message.recipient', 1),
        scopedSlots: { customRender: 'recipient' },
      },
      {
        key: 'receivedTime',
        title: this.$t('message.receivedTime'),
        scopedSlots: { customRender: 'receivedTime' },
      },
      {
        key: 'operations',
        width: 160,
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }
  private get isChildRoute(): boolean {
    return this.$route.name !== 'inbox'
  }
  private get locale(): string {
    return this.$store.state.locale
  }
  private get memberId(): any {
    this.$set(this.filter, 'recipient', this.$store.state.memberId)
    return this.$store.state.memberId
  }
  private get messageTypes(): any {
    return [
      {
        key: 0,
        value: this.$t('common.all'),
      },
      {
        key: '1633',
        value: this.$t('message.messageTypes.takeLeave'),
      },
      {
        key: '1634',
        value: this.$t('message.messageTypes.cancelLeave'),
      },
      {
        key: '1629',
        value: this.$t('message.messageTypes.noRecord'),
      },
      {
        key: '1630',
        value: this.$t('message.messageTypes.missNightStudy'),
      },
      {
        key: '1631',
        value: this.$t('message.messageTypes.miss'),
      },
      {
        key: '1632',
        value: this.$t('message.messageTypes.changeClass'),
      },
      {
        key: '1637',
        value: this.$t('message.messageTypes.studentLog'),
      },
    ]
  }
  private get senderTypes(): any {
    return [
      {
        key: 0,
        value: this.$t('common.all'),
      },
      {
        key: '2002',
        value: this.$t('common.student'),
      },
      {
        key: '2001',
        value: this.$t('common.teacher'),
      },
      {
        key: '2000',
        value: this.$t('common.parent'),
      },
    ]
  }
  private get type(): any {
    return this.$route.params.mesType
  }

  private get unreadSystemNum(): any {
    return this.$store.state.unreadSystemNum
  }

  private get unreadStatus(): any {
    return this.$store.state.unreadStatus
  }

  private allRead(): void {
    MessageController.setReadBatch().then(res => {
      this.$message.success(this.$tc('common.operateSuccess'))
      this.refresh()
      this.getMessageStatus()
    })
  }

  private getMessageStatus(): void {
    Promise.all([MessageController.hasUnread(), MessageController.unreadSystemMessage()])
      .then(res => {
        this.$store.commit('setUnreadStatus', res[0].data)
        this.$store.commit('setSystemUnreadNum', res[1].data)
      })
      .catch(err => {
        console.error(err)
      })
  }

  private changeType({ item, key, keyPath }): void {
    this.filter = {
      title: null,
      recipient: this.memberId,
      houseGroupId: 0,
      memberType: 0,
      sender: null,
      receivedTime: [],
      homeroomId: [],
      messageType: 0,
    }
    this.loading = false
    this.$router.replace({ params: { mesType: key } }).catch(err => {})
  }

  private created(): void {
    this.getDropDownInfo()
  }

  private deleteRecord(record): void {
    MessageController.deleteTo(record.messageId).then(res => {
      this.$message.success(this.$tc('common.deleteSuccess'))
      this.refresh()
    })
  }

  private filterData = filterData

  private forward(messageId, messageMasterId): void {
    this.$router
      .push({ name: 'sendMessage', query: { messageId, messageMasterId, type: 'inbox' } })
      .catch(err => {})
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.authTeachersForMessage(),
      DropDownController.getHouseGroupListAll(),
      DropDownController.getClassList(),
    ])
      .then(res => {
        this.recipients = cloneDeep(res[0].data)
        this.advisories = res[1].data
        this.homerooms = clearEmptyArray(res[2].data)
        this.$set(this.filter, 'recipient', this.memberId)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.init = true
        this.getData()
      })
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.loading = true
    this.data = []
    const type = this.type
    const reqId = ++this.reqId
    const {
      title,
      sender,
      recipient,
      receivedTime,
      messageType,
      memberType,
      homeroomId,
      houseGroupId,
    } = this.filter
    const condition = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      title: title ? title.trim() : undefined,
      fromMemberName: sender ? sender.trim() : undefined,
      toMemberId: this.type === 'normal' ? recipient || undefined : undefined,
      memberType: memberType || undefined,
      classId: homeroomId[1],
      houseGroupId: houseGroupId || undefined,
      startTime: receivedTime[0] ? moment(receivedTime[0]).valueOf() : undefined,
      endTime: receivedTime[1] ? moment(receivedTime[1]).valueOf() : undefined,
      queryType: this.type === 'normal' ? 'Normal' : 'SystemMessage',
      messageType: this.type === 'normal' ? undefined : messageType || undefined,
    }
    // if(this.type === 'system'){
    //   this.getUnreadSystem()
    // }
    this.getMessageIds(condition, reqId)
    MessageController.getToList(condition)
      .then(res => {
        if (type !== this.type || reqId !== this.reqId) return
        const mes4Type = {
          1629: date =>
            `Dear administration and teachers,Today, as of 3:30PM on 【${moment(date).format(
              'YYYY/MM/DD'
            )}】,\
             the following faculty and staff’s classes has incomplete attendance (1 or more students on the class roster still has ‘No record’)`,
          1630: date =>
            `Hello Director of Residential Life,\
            Please see below for the list of students marked absent (unexcused) and absent (excused) as of 6:15PM on 【${moment(
              date
            ).format('YYYY/MM/DD')}】 for night study.`,
          1632: date =>
            `Dear faculty and staff members,\
            As of 8:00AM on 【${moment(date).format(
              'YYYY/MM/DD'
            )}】, the following students have been moved in or out of your class(es): `,
          1633: () =>
            `老师：您好，您有学生请假离校，请查收请假信息。\
            Teacher: Hello, you have students leaving school, please check the leave information.`,
          1634: () =>
            `老师：您好，您有学生的请假改为正常到校，请查收详细信息。\
            Teacher: Hello, today you have students who asked for leave to arrive at school normally. Please check the details.`,
          1631: date =>
            `Dear faculty and staff,\
            The following students in elementary school are absent as of 【9:00AM this morning/5:00PM this afternoon】 on 【${moment(
              date
            ).format('YYYY/MM/DD')}】`,
          1637: date =>
            `老师：您好，您有学生新增了一条日记，请查收详情信息。\
            Teacher: Hello, today you have students who added a new daybook. Please check the details.`,
        }

        this.data = res.data.items.map(item => ({
          ...item,
          content: mes4Type[item.type]?.(item.sendTime) || item.content,
        }))
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private getMessageIds(condition, reqId): void {
    const type = this.type
    MessageController.getToListIds({
      ...condition,
      pageSize: 0,
      pageCurrent: 1,
    }).then(res => {
      if (type !== this.type || reqId !== this.reqId) return
      this.$store.commit('setAllMessageIds', res.data)
    })
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private inputChange = debounce(() => {
    this.getData()
  }, 1000)

  private reset(): void {
    this.filter = {
      title: null,
      recipient: this.memberId,
      houseGroupId: 0,
      memberType: 0,
      sender: null,
      receivedTime: [],
      homeroomId: [],
      messageType: 0,
    }
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private sendMessage(): void {
    this.$router.push({ name: 'sendMessage' }).catch(err => {})
  }

  private setRecGroup(): void {
    this.recVis = true
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private reply(messageId, messageMasterId): void {
    this.$router.push({
      name: 'replyMessage',
      query: { messageId, messageMasterId, type: 'inbox' },
    })
  }

  private viewDetail(memberId, messageId, readFlag): void {
    if (memberId === this.$store.state.memberId && !readFlag) {
      MessageController.setRead(messageId)
    }
    this.$router
      .push({ name: 'inDetail', params: { messageId }, query: { mesType: this.type } })
      .catch(err => {})
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (to.name === 'inbox' && !to.params.mesType) {
      this.$router.replace({ params: { mesType: 'normal' } })
      return
    }
    if (from && from.meta.refresh === true) {
      this.refresh()
    }
    if (from?.name === to.name && from.params.mesType !== to?.params.mesType && this.init) {
      this.getData()
    }
  }
}
