










































































import { Vue, Component, Prop } from 'vue-property-decorator'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { createPagination } from '@/constant/constant'
import { i18n } from '@/i18n/i18n'
import moment from 'moment'
import { humpFormat } from '@/utils/utils'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class AbsentMes extends Vue {
  @Prop({}) private type!: any
  @Prop({}) private date!: any
  @Prop({}) private data!: any

  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })

  private get locale(): any {
    return this.$store.state.locale
  }

  private get campusDatas(): any {
    if (!this.data || !Object.keys(this.data || {}).length) return []
    let campuses = [] as any
    Object.keys(this.data)
      .sort()
      .forEach(key => {
        campuses.push({
          getColumns: time => {
            return this.cColumns.concat(
              [
                key === 'elementary'
                  ? {
                      dataIndex: 'className',
                      title: '班级Homeroom',
                      ellipsis: true,
                      customHeaderCell: (record, index) => {
                        return {
                          attrs: {
                            title: '班级Homeroom',
                          },
                        }
                      },
                      scopedSlots: { customRender: 'commonFlex' },
                    }
                  : undefined,
                ['middleSchool', 'upperSchool'].includes(key)
                  ? {
                      dataIndex: 'teacherNames',
                      title: '导师Advisor',
                      ellipsis: true,
                      customHeaderCell: (record, index) => {
                        return {
                          attrs: {
                            title: '导师Advisor',
                          },
                        }
                      },
                      scopedSlots: { customRender: 'nameFlex' },
                    }
                  : undefined,
                {
                  dataIndex: 'status',
                  title: '出勤标记Attendance Code',
                  ellipsis: true,
                  customHeaderCell: (record, index) => {
                    return {
                      attrs: {
                        title: '出勤标记Attendance Code',
                      },
                    }
                  },
                  scopedSlots: { customRender: 'status' },
                },
                time
                  ? {
                      key: 'times',
                      title: '时间段Times',
                      ellipsis: true,
                      customHeaderCell: (record, index) => {
                        return {
                          attrs: {
                            title: '时间段Times',
                          },
                        }
                      },
                      scopedSlots: { customRender: 'time' },
                    }
                  : undefined,
              ].filter(item => item)
            )
          },
          title: `The following students in ${humpFormat(key)} are absent as of ${
            moment(this.date).isBefore(moment(this.date).hour(12), 'hour')
              ? '9:00AM this morning'
              : '5:00PM this afternoon'
          } \
         on ${moment(this.date).format('YYYY/MM/DD')}`,
          eData: this.data[key].filter(item => item.status === 'personal'),
          ueData: this.data[key].filter(item => item.status === 'absent'),
          ePagination: createPagination({
            showTotal: total => i18n.t('common.pageTotal', { total }),
          }),
          uePagination: createPagination({
            showTotal: total => i18n.t('common.pageTotal', { total }),
          }),
          campus: key,
        })
      })
    return campuses
  }

  private get cColumns(): any {
    return [
      {
        dataIndex: 'name',
        title: '中文名',
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'firstName',
        title: '名Name',
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'lastName',
        title: '姓Last Name',
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: '姓Last Name',
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'sectionName',
        title: '年级Grade',
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: '年级Grade',
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
    ]
  }

  private getTimeStr(duration: any = []) {
    return duration
      .map(times =>
        Vue.filter('doubleMoment')([times.startTime, times.endTime], 'YYYY.MM.DD HH:mm')
      )
      .join(',')
  }

  private handleTableChange(pagination, target): void {
    target.current = pagination.current
    target.pageSize = pagination.pageSize
  }
}
