

















































































































































import {
  DropDownController,
  MessageController,
  TeacherController,
} from '@/services/request.service'
import { Vue, Component, Prop, Model, Emit, Watch } from 'vue-property-decorator'
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
import GroupTransfer from '@/components/GroupTransfer.vue'
import TextFold from '@/components/TextFold.vue'
const operations = {
  add: function(condition) {
    return MessageController.addMemberGroup(condition)
  },
  edit: function(condition) {
    return MessageController.updateMemberGroup(condition)
  },
}

@Component({
  components: {
    GroupTransfer,
    TextFold,
  },
})
export default class SetRecModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean

  private currentType: any = 'students'
  private data: any = {}
  private dataLoading: any = false
  private editGroup: any = {}
  private editType: any = 'add'
  private groupForm: any = {}
  private groupTransferVisible: any = false
  private groupTransferTitle: any = ''
  private loading: any = false
  private modalVis: any = false
  private parents: any = []
  private students: any = []
  private teachers: any = []
  private transferCandidate: Array<any> = []
  private transferSelected: Array<any> = []
  private transferType: any = ''
  private init: any = false

  private get data4Type(): any {
    return this.data[this.currentType] || []
  }

  private get recipientError(): any {
    return this.groupForm.members && !(this.groupForm.members || []).length
  }

  private set recipientError(val) {
    if (val) {
      this.$set(this.groupForm, 'members', [])
    }
  }

  private get selfId(): any {
    return this.$store.state.memberId
  }

  private get types(): any {
    return [
      {
        key: 'students',
        value: this.$t('common.student'),
      },
      {
        key: 'parents',
        value: this.$t('common.parent'),
      },
      {
        key: 'teachers',
        value: this.$t('common.teacher'),
      },
    ]
  }

  private addGroup(): void {
    this.modalVis = true
    this.editType = 'add'
    this.groupForm = {
      type: this.currentType,
    }
  }

  private addRec(): void {
    this.groupTransferVisible = true
    this.transferType = this.groupForm.type
    this.transferCandidate = this[this.transferType]
    this.transferSelected = this.groupForm.members
    this.groupTransferTitle = this.$t(`common.${this.transferType.slice(0, -1)}`) as string
  }

  private beforeClose(): void {
    this.$confirm({
      title: this.$t('common.unsaveConfirm') as string,
      onOk: () => {
        this.modalVis = false
      },
      onCancel: () => {},
    })
  }

  private confirm(): void {
    const form = this.$refs['groupForm'] as any
    form.validate(valid => {
      let otherValid = true
      if (!this.groupForm.members || !this.groupForm.members?.length) {
        this.recipientError = true
        otherValid = false
      }
      if (!valid || !otherValid) return false
      const typeCode = {
        parents: '2000',
        students: '2002',
        teachers: '2001',
      }
      const condition = {
        groupId: this.editType === 'edit' ? this.groupForm.groupId : undefined,
        name: this.groupForm.name,
        type: typeCode[this.groupForm.type],
        members: this.groupForm.members.map(recipient =>
          this.groupForm.type !== 'parents'
            ? {
                memberId: recipient.id,
              }
            : {
                memberId: parseInt(recipient.id.split('-')[0]),
                studentId: parseInt(recipient.id.split('-')[1]),
              }
        ),
      }
      operations[this.editType](condition).then(res => {
        this.getGroupList()
        this.$message.success(this.$tc('common.saveSuccess'))
        this.modalVis = false
      })
    })
  }

  private deleteGroup(group): void {
    MessageController.deleteMemberGroup(group.groupId).then(res => {
      this.getGroupList()
      this.$message.success(this.$tc('common.deleteSuccess'))
    })
  }

  private edit(group): void {
    this.editGroup = group
    this.groupForm = {
      ...group,
      members: group.members.map(member =>
        this.currentType !== 'parents'
          ? {
              id: member.memberId,
              title: (member.enName + ' ' + member.name).trim(),
            }
          : {
              id: member.memberId + '-' + member.studentId,
              title: member.name + this.$t('relation.' + member.relationship),
            }
      ),
      type: this.currentType,
    }
    this.modalVis = true
    this.editType = 'edit'
  }

  private getDropDownInfo(): void {
    if (this.init) return
    this.init = true
    this.dataLoading = true
    Promise.all([
      TeacherController.getListAll(),
      DropDownController.getHeadTeachers(),
      DropDownController.getCourseTeachers(),
      DropDownController.getTutors(),
      DropDownController.getAuthStudentsGroupBySectionClass(),
      DropDownController.getAuthStudentsGroupByHouse(),
      DropDownController.getAuthStudentGroupByCourse(),
      DropDownController.getAuthStudentParentsGroupBySectionClass(),
      DropDownController.getAuthStudentParentsGroupByHouse(),
      DropDownController.getAuthStudentParentsGroupByCourse(),
    ])
      .then(res => {
        const allTeachers = res[0].data
          .map(teacher => {
            return {
              title: (teacher.enName + ' ' + teacher.name).trim(),
              id: teacher.teacherId,
              type: 'teacher',
              relation: '',
            }
          })
          .filter(item => item.id !== this.selfId)
        const headTeachers = res[1].data
          .map((grade, index) => {
            return {
              id: index,
              expand: false,
              title: grade.groupLabel || this.$t('transfer.ungrouped'),
              children: grade.list
                .map(teacher => {
                  return {
                    id: teacher.teacherId,
                    title: teacher.teacherName,
                    titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
                  }
                })
                .filter(item => item.id !== this.selfId),
            }
          })
          .filter(item => item.children.length)
        const subjectTeachers = res[2].data
          .map((subject, index) => {
            return {
              id: index,
              expand: false,
              title: subject.groupLabel || this.$t('transfer.ungrouped'),
              children: subject.list
                .map(teacher => {
                  return {
                    id: teacher.teacherId,
                    title: teacher.teacherName,
                    titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
                  }
                })
                .filter(item => item.id !== this.selfId),
            }
          })
          .filter(item => item.children.length)
        const tutors = res[3].data
          .map((advisory, index) => {
            return {
              id: index,
              expand: false,
              title: advisory.groupLabel || this.$t('transfer.ungrouped'),
              children: advisory.list
                .map(teacher => {
                  return {
                    id: teacher.teacherId,
                    title: teacher.teacherName,
                    titleWithRelation: `${teacher.teacherName} (${teacher.relationName})`.trim(),
                  }
                })
                .filter(item => item.id !== this.selfId),
            }
          })
          .filter(item => item.children.length)
        this.teachers = {
          all: {
            list: allTeachers,
            transferType: 'normal',
          },
          grade: {
            list: headTeachers,
            transferType: 'group',
          },
          advisory: {
            list: tutors,
            transferType: 'group',
          },
          subjectClass: {
            list: subjectTeachers,
            transferType: 'group',
          },
        }

        let homeroomStudent = res[4].data.map(section => {
          return {
            id: section.sectionId,
            expand: false,
            title: section.sectionName || this.$t('transfer.ungrouped'),
            children: section.classStudents.map(group => {
              return {
                id: group.classId,
                title: group.className || this.$t('transfer.undivided'),
                children: group.students.map(student => ({
                  id: student.studentId,
                  title: student.name,
                  isPre: student.status === '1017',
                  admissionTime: moment(student.enterDate),
                })),
              }
            }),
          }
        })
        let advisoryStudent = res[5].data.map(advisory => {
          return {
            id: advisory.houseId,
            expand: false,
            title: advisory.houseName || this.$t('transfer.ungrouped'),
            children: advisory.subItems.map(group => {
              return {
                id: group.houseGroupId,
                title: group.houseGroupName,
                children: group.students.map(student => ({
                  id: student.studentId,
                  title: student.name,
                  isPre: student.status === '1017',
                  admissionTime: moment(student.enterDate),
                })),
              }
            }),
          }
        })
        let courseStudent = res[6].data.map(section => {
          return {
            id: section.sectionCampusId,
            expand: false,
            title: section.sectionName || this.$t('transfer.ungrouped'),
            children: section.courses.map(course => {
              return {
                id: course.courseId,
                title: course.description,
                children: course.students.map(student => ({
                  id: student.studentId,
                  title: student.name,
                  isPre: student.status === '1017',
                  admissionTime: moment(student.enterDate),
                })),
              }
            }),
          }
        })
        this.students = {
          homeroom: {
            list: homeroomStudent,
            transferType: 'doubleGroup',
          },
          advisory: {
            list: advisoryStudent,
            transferType: 'doubleGroup',
          },
          subjectClass: {
            list: courseStudent,
            transferType: 'doubleGroup',
          },
        }

        let homeroomParent = res[7].data.map(section => {
          return {
            id: section.sectionId,
            expand: false,
            title: section.sectionName || this.$t('transfer.ungrouped'),
            children: section.classStudents.map(group => {
              return {
                id: group.classId,
                title: group.className || this.$t('transfer.undivided'),
                children: group.studentParents.map(parent => ({
                  id: parent.parentId + '-' + parent.studentId,
                  title: parent.studentName + this.$t('relation.' + parent.relationship),
                  selected: false,
                  isPre: parent.studentStatus === '1017',
                  admissionTime: moment(parent.enterDate),
                })),
              }
            }),
          }
        })
        let advisoryParent = res[8].data.map(advisory => {
          return {
            id: advisory.houseId,
            expand: false,
            title: advisory.houseName || this.$t('transfer.ungrouped'),
            children: advisory.subItems.map(group => {
              return {
                id: group.houseGroupId,
                title: group.houseGroupName,
                children: group.studentParents.map(parent => ({
                  id: parent.parentId + '-' + parent.studentId,
                  title: parent.studentName + this.$t('relation.' + parent.relationship),
                  selected: false,
                  isPre: parent.studentStatus === '1017',
                  admissionTime: moment(parent.enterDate),
                })),
              }
            }),
          }
        })
        let courseParent = res[9].data.map(section => {
          return {
            id: section.sectionCampusId,
            expand: false,
            title: section.sectionName || this.$t('transfer.ungrouped'),
            children: section.courses.map(course => {
              return {
                id: course.courseId,
                title: course.description,
                children: course.parents.map(parent => ({
                  id: parent.parentId + '-' + parent.studentId,
                  title: parent.studentName + this.$t('relation.' + parent.relationship),
                  selected: false,
                  isPre: parent.studentStatus === '1017',
                  admissionTime: moment(parent.enterDate),
                })),
              }
            }),
          }
        })
        this.parents = {
          homeroom: {
            list: homeroomParent,
            transferType: 'doubleGroup',
          },
          advisory: {
            list: advisoryParent,
            transferType: 'doubleGroup',
          },
          subjectClass: {
            list: courseParent,
            transferType: 'doubleGroup',
          },
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.dataLoading = false
      })
  }

  private getGroupList(): void {
    // parents: '2000',
    // teachers: '2001'
    // students: '2002',
    this.loading = true
    Promise.all([
      MessageController.memberGroupList('2000'),
      MessageController.memberGroupList('2001'),
      MessageController.memberGroupList('2002'),
    ])
      .then(res => {
        this.data = {
          parents: res[0].data,
          teachers: res[1].data,
          students: res[2].data,
        }
      })
      .finally(() => (this.loading = false))
  }

  private onTypeChange(): void {
    this.groupForm.members = undefined
  }

  private removeItem(id): void {
    const index = this.groupForm.members.findIndex(teacher => teacher.id === id)
    this.groupForm.members.splice(index, 1)
    this.$forceUpdate()
  }

  private getSelectedData(data): void {
    this.$set(this.groupForm, 'members', cloneDeep(data))
  }

  @Watch('visible')
  private onVisChange(val): void {
    if (val) {
      this.getGroupList()
      this.getDropDownInfo()
    } else {
      this.data = {}
    }
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }
}
