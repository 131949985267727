




































































import { Vue, Component, Prop } from 'vue-property-decorator'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { createPagination } from '@/constant/constant'
import { i18n } from '@/i18n/i18n'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class CommonMes extends Vue {
  @Prop({}) private type!: any
  @Prop({}) private date!: any
  @Prop({}) private data!: any

  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })

  private get locale(): any {
    return this.$store.state.locale
  }

  private get rData(): any {
    return this.data?.list || []
  }

  private get realCol(): any {
    const type2Col = {
      1629: 'noColumns',
      1630: 'nightColumns',
      1632: 'cColumns',
    }
    return this[type2Col[this.type]]
  }

  private get noColumns(): any {
    return [
      {
        dataIndex: 'personNames',
        title: 'Name',
        ellipsis: true,
        scopedSlots: { customRender: 'nameFlex' },
      },
      {
        dataIndex: 'className',
        title: 'Class/Advisory/Life Block',
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: 'Class/Advisory/Life Block',
            },
          }
        },
        ellipsis: true,

        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'campusNames',
        title: 'School Division',
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: 'School Division',
            },
          }
        },
        scopedSlots: { customRender: 'nameFlex' },
      },
      {
        dataIndex: 'count',
        title: 'No. of Students with ‘No Record’',
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: 'No. of Students with ‘No Record’',
            },
          }
        },
        ellipsis: true,
      },
      {
        dataIndex: 'period',
        title: 'Period/ Block',
        ellipsis: true,
      },
    ]
  }

  private get nightColumns(): any {
    return [
      {
        dataIndex: 'name',
        title: 'Chinese Name',
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: 'Chinese Name',
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'studentNum',
        title: 'Student ID',
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'enName',
        title: 'English Name',
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: 'English Name',
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'lastName',
        title: 'Last Name(pinyin)',
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: 'Last Name(pinyin)',
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'sectionName',
        title: 'Grade',
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'selfStudyRoomName',
        title: 'Night Study',
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: 'Night Study',
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'classCode',
        title: 'Night Study Room',
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: 'Night Study Room',
            },
          }
        },
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'status',
        title: 'Attendance Code for Night Study',
        ellipsis: true,
        customHeaderCell: (record, index) => {
          return {
            attrs: {
              title: 'Attendance Code for Night Study',
            },
          }
        },
        scopedSlots: { customRender: 'status' },
      },
    ]
  }

  private get cColumns(): any {
    return [
      {
        dataIndex: 'firstName',
        title: this.$t('common.givenName'),
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'lastName',
        title: this.$t('common.surname'),
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'name',
        title: this.$t('common.cnName'),
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'sectionName',
        title: this.$t('common.grade'),
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'courseNames',
        title: 'Previous Class Name',
        ellipsis: true,
        scopedSlots: { customRender: 'nameFlex' },
      },
      {
        dataIndex: 'teacherNames',
        title: 'Previous Teacher',
        ellipsis: true,
        scopedSlots: { customRender: 'nameFlex' },
      },
      {
        dataIndex: 'newCourseName',
        title: 'New Class Name',
        ellipsis: true,
        scopedSlots: { customRender: 'commonFlex' },
      },
      {
        dataIndex: 'newTeacherNames',
        title: 'New Teacher',
        ellipsis: true,
        scopedSlots: { customRender: 'nameFlex' },
      },
    ]
  }

  private handleTableChange(pagination): void {
    this.pagination.current = pagination.current
    this.pagination.pageSize = pagination.pageSize
  }
}
