























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { DropDownController, MessageController } from '@/services/request.service'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import moment from 'moment'
import TableTotal from '@/components/TableTotal.vue'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import { clearEmptyArray, filterData, toPage, getSchoolInfo } from '@/utils/utils'
import SetRecModal from './components/SetRecModal.vue'
import ReadDetail from './components/ReadDetail.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    TableTotal,
    filterGroup,
    FlexTooltip,
    SetRecModal,
    ReadDetail,
  },
})
export default class Outbox extends Vue {
  private advisories: Array<any> = []
  private data: Array<any> = []
  private detailId: any = ''
  private filter: any = {
    title: null,
    recipient: null,
    sender: 0,
    sendTime: [],
    houseGroupId: 0,
    memberType: 0,
    homeroomId: [],
  }
  private homerooms: any = []
  private loading: boolean = false
  private moment = moment
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private readDetailVis: boolean = false
  private recVis: any = false
  private senders: Array<any> = []

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'outbox'
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    return [
      {
        key: 'title',
        title: this.$t('message.title'),
        scopedSlots: { customRender: 'name' },
        align: 'left',
      },
      {
        key: 'content',
        title: this.$t('message.content'),
        ellipsis: true,
        scopedSlots: { customRender: 'content' },
        align: 'left',
      },
      {
        key: 'sender',
        title: this.$t('message.sender'),
        align: 'left',
        scopedSlots: { customRender: 'sender' },
      },
      {
        key: 'recipient',
        title: this.$tc('message.recipient', 1),
        align: 'left',
        scopedSlots: { customRender: 'recipient' },
      },
      {
        key: 'readStatus',
        title: this.$t('message.readStatus'),
        align: 'left',
        scopedSlots: { customRender: 'readStatus' },
      },
      {
        key: 'sendTime',
        title: this.$t('message.sendTime'),
        align: 'left',
        scopedSlots: { customRender: 'sendTime' },
      },
      {
        key: 'operations',
        width: 200,
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  private get memberTypes(): any {
    return [
      {
        key: 0,
        value: this.$t('common.all'),
      },
      {
        key: '2002',
        value: this.$t('common.student'),
      },
      {
        key: '2001',
        value: this.$t('common.teacher'),
      },
      {
        key: '2000',
        value: this.$t('common.parent'),
      },
    ]
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to, from): void {
    if (from && from.meta.refresh === true) {
      this.refresh()
    }
  }

  private get memberId(): any {
    // this.$set(this.filter, 'recipient', this.$store.state.memberId)
    return this.$store.state.memberId
  }

  private created(): void {
    this.getDropDownInfo()
  }

  private deleteRecord(record): void {
    MessageController.deleteFrom(record.messageMasterId).then(res => {
      this.$message.success(this.$tc('common.deleteSuccess'))
      this.refresh()
    })
  }

  private filterData = filterData

  private reset(): void {
    this.filter = {
      title: null,
      recipient: null,
      sender: 0,
      sendTime: [],
      houseGroupId: 0,
      memberType: 0,
      homeroomId: [],
    }
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private forward(messageMasterId): void {
    this.$router
      .push({
        name: 'sendMessage',
        query: { messageId: messageMasterId, messageMasterId, type: 'outbox' },
      })
      .catch(err => {})
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.authTeachersForMessage(),
      DropDownController.getHouseGroupListAll(),
      DropDownController.getClassList(),
    ])
      .then(res => {
        this.senders = cloneDeep(res[0].data)
        this.advisories = res[1].data
        this.homerooms = clearEmptyArray(res[2].data)
        this.$set(this.filter, 'sender', this.memberId)
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.getData()
      })
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.data = []
    this.loading = true
    const { title, sender, recipient, sendTime, memberType, homeroomId, houseGroupId } = this.filter
    let condition = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      title: title ? title.trim() : undefined,
      fromMemberId: sender || undefined,
      toMemberName: recipient ? recipient.trim() : undefined,
      startTime: sendTime[0] ? moment(sendTime[0]).valueOf() : undefined,
      endTime: sendTime[1] ? moment(sendTime[1]).valueOf() : undefined,
      memberType: memberType || undefined,
      classId: homeroomId[1],
      houseGroupId: houseGroupId || undefined,
    }
    this.getMessageIds(condition)
    MessageController.getFromList(condition)
      .then(res => {
        this.data = res.data.items.map(item => {
          return {
            ...item,
            toMember: item.toMembers
              .map(mem => {
                if (mem.memberType === '2000') {
                  return (
                    mem.enName +
                    ' ' +
                    mem.name +
                    (mem.relationship ? this.$t('relation.' + mem.relationship) : '')
                  ).trim()
                } else {
                  return (mem.enName + ' ' + mem.name).trim()
                }
              })
              .join(', '),
            totalNum: item.toMembers.length,
          }
        })
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private getMessageIds(condition): void {
    MessageController.getFromListIds({
      ...condition,
      pageSize: 0,
      pageCurrent: 1,
    }).then(res => {
      console.log(res)
      this.$store.commit('setAllMessageIds', res.data)
    })
  }

  private inputChange = debounce(() => {
    this.getData()
  }, 1000)

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private recall(messageId): void {
    MessageController.withdraw(messageId).then(res => {
      if (res.data) {
        this.$message.success(this.$tc('message.recallSuccess'))
      } else {
        this.$message.error(this.$tc('message.recallFailed'))
      }
      this.refresh()
    })
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private showReadDetail(record): void {
    this.readDetailVis = true
    this.detailId = record.messageMasterId
  }

  private sendMessage(): void {
    this.$router.push({ name: 'sendMessage' }).catch(err => {})
  }

  private viewDetail(messageId): void {
    this.$router.push({ name: 'outDetail', params: { messageId } }).catch(err => {})
  }
}
