




































import { MessageController } from '@/services/request.service'
import { Vue, Component, Prop, Emit, Model, Watch } from 'vue-property-decorator'
import TextFold from '@/components/TextFold.vue'

@Component({
  components: {
    TextFold,
  },
})
export default class ReadDetail extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop({}) private messageId!: any

  private readInfo = {}
  private loading = false
  private currentType = 'readMembers'

  private get data4Type(): any {
    return this.readInfo[this.currentType] || []
  }

  private get types(): any {
    return [
      {
        key: 'readMembers',
        value: this.$t('message.read'),
      },
      {
        key: 'unreadMembers',
        value: this.$t('message.unread'),
      },
    ]
  }

  private getReadInfo(): void {
    this.loading = true
    MessageController.getReadInfo(this.messageId)
      .then(res => {
        let data: any = {}
        Object.keys(res.data).forEach(key => {
          let student = res.data[key].filter(member => member.memberType === '2002')
          let teacher = res.data[key].filter(member => member.memberType === '2001')
          let parent = res.data[key].filter(member => member.memberType === '2000')
          data[key] = [
            teacher.length
              ? {
                  names: teacher
                    .map(member => (member.enName + ' ' + member.name).trim())
                    .join('、'),
                  type: 'teacher',
                }
              : undefined,
            student.length
              ? {
                  names: student
                    .map(member => (member.enName + ' ' + member.name).trim())
                    .join('、'),
                  type: 'student',
                }
              : undefined,
            parent.length
              ? {
                  names: parent
                    .map(member =>
                      (member.name + this.$t('relation.' + member.relationship)).trim()
                    )
                    .join('、'),
                  type: 'parent',
                }
              : undefined,
          ].filter(item => item)
        })
        this.readInfo = data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  @Watch('visible')
  private onVisibleChange(val) {
    if (val) {
      this.readInfo = {}
      this.getReadInfo()
    }
  }

  @Emit('change')
  private closeModal(): boolean {
    return false
  }
}
